/* Blue */
$primary-color: #C10230;
$primary-bg: #F0F5FF;

/* Green */
$third-color: #1EC75C;

/* Grey */
$secondary-color: #515151;
$rt-secondary-color: #454545;
$border-color : #C1C1C1;

/* Yellow */
$accent-color: #002d74;

/* Red */
$rt-color: #1E63FF;
$warn-color: #FF0027;

$white: #fff;

/** MATERIAL PALETTE **/

$jtred: (
  50: $rt-color,
  100: $rt-color,
  200: $rt-color,
  300: $rt-color,
  400: $rt-color,
  500: $rt-color,
  600: $rt-color,
  700: $rt-color,
  800: $rt-color,
  900: $rt-color,
  A100: $rt-color,
  A200: $rt-color,
  A400: $rt-color,
  A700: $rt-color,
  contrast: (50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$jtblue: (
  50: $primary-color,
  100: $primary-color,
  200: $primary-color,
  300: $primary-color,
  400: $primary-color,
  500: $primary-color,
  600: $primary-color,
  700: $primary-color,
  800: $primary-color,
  900: $primary-color,
  A100: $primary-color,
  A200: $primary-color,
  A400: $primary-color,
  A700: $primary-color,
  contrast: (50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$jtgreen: (
  50: $third-color,
  100: $third-color,
  200: $third-color,
  300: $third-color,
  400: $third-color,
  500: $third-color,
  600: $third-color,
  700: $third-color,
  800: $third-color,
  900: $third-color,
  A100: $third-color,
  A200: $third-color,
  A400: $third-color,
  A700: $third-color,
  contrast: (50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$jtwarn: (
  50: $warn-color,
  100: $warn-color,
  200: $warn-color,
  300: $warn-color,
  400: $warn-color,
  500: $warn-color,
  600: $warn-color,
  700: $warn-color,
  800: $warn-color,
  900: $warn-color,
  A100: $warn-color,
  A200: $warn-color,
  A400: $warn-color,
  A700: $warn-color,
  contrast: (50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$jtaccent: (
  50:$accent-color,
  100:$accent-color,
  200:$accent-color,
  300:$accent-color,
  400:$accent-color,
  500:$accent-color,
  600:$accent-color,
  700:$accent-color,
  800:$accent-color,
  900:$accent-color,
  A100:$accent-color,
  A200:$accent-color,
  A400:$accent-color,
  A700:$accent-color,
  contrast: (50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);