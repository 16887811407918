@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'brand-colors';

@include mat.core();

// Theme
$primary: mat.define-palette($jtblue, 100, 50, 200);
$accent: mat.define-palette($jtaccent, 100, 500, A100);
$warn: mat.define-palette($jtwarn, 100, 50, 200);
$custom-theme: mat.define-light-theme($primary, $accent, $warn);

// Theme RT
/*$rt-primary: mat.define-palette($jtred, 100, 50, 200);
$rt-theme: mat.define-light-theme($rt-primary, $accent, $warn);*/

//.main-theme {
  @include mat.all-component-themes($custom-theme);
//}


/* .rt-theme {
  @include mat.all-component-themes($rt-theme);
} */

// Typo
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$typography: mat.define-typography-config(
  $font-family: 'Open Sans',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(15px, 22px, 400)
);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
/* @include mat.typography-hierarchy($custom-typography);

// Override typography for a specific Angular Material components.
@include mat.checkbox-typography($custom-typography); */

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($typography);
